import { css } from '@emotion/css/macro';
import { HotKey } from './types';
import { middlegrey } from '../ui/Core/stylesheets/colors';
import { parseKeyCodeToName } from './helpers';

const rootClass = css`
	padding: 4px 8px;
	border: 1px solid ${middlegrey};
	border-radius: 5px;
	margin-left: 8px;
`;

type HotKeyDisplayProps = { hotKey: HotKey; isEditing?: boolean };

export default function HotKeyDisplay({ hotKey, isEditing }: HotKeyDisplayProps) {
	return (
		<div className={rootClass}>
			{isEditing && (
				<>
					Press a combination...
					<br />
				</>
			)}
			{hotKey.map((key) => parseKeyCodeToName(key)).join(' + ')}
		</div>
	);
}
