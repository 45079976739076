import React from 'react';
import { HotKey, HotKeyAction } from './types';
import { useAuthContext } from '../auth/context';
import { makeKeyboardListener, parseHotKeysMapJson } from './helpers';

export function useHotKeyHandlers(actions: { key: HotKey; func: () => any }[]) {
	React.useEffect(() => {
		const listeners: ((e: KeyboardEvent) => any)[] = actions.map(({ key, func }) => (e) => {
			makeKeyboardListener(key, e, func);
		});

		listeners.forEach((l) => {
			window.addEventListener('keyup', l);
		});

		return () => {
			listeners.forEach((l) => window.removeEventListener('keyup', l));
		};
	}, [actions]);

	return actions;
}

export function useUserHotKeys(actions: { action: HotKeyAction; func: () => any }[]) {
	const { hotKeysMapJson }: any = useAuthContext();
	const parsedKeys = React.useMemo(() => parseHotKeysMapJson(hotKeysMapJson), [hotKeysMapJson]);

	useHotKeyHandlers(actions.map(({ action, func }) => ({ key: parsedKeys[action], func })));

	return parsedKeys;
}
