import gql from 'graphql-tag';

export default gql`
	query getUsersOfProject($projectId: String!, $includeContacts: Boolean, $area: Number) {
		list: get_api_Project_ViewUsersOfProject(
			projectId: $projectId
			includeContacts: $includeContacts
			area: $area
		) @rest(path: "Project/ViewUsersOfProject?{args}") {
			data {
				id
				clientId
				statusId
				name
				email
				phone
				jobTitle
				responsibilityArea
				projectRole
				subRole
				organization
				isPrimary
				isISA
				attendeeType
				mentorId
			}
		}
	}
`;
