import { HotKey, HotKeysMap } from './types';
import { has } from '../core/helpers';
import {
	ARROW_LEFT,
	ARROW_RIGHT,
	defaultHotKeys,
	MODIFIER_ALT,
	MODIFIER_CTRL,
	MODIFIER_META,
	MODIFIER_SHIFT,
} from './constants';

export function parseHotKeysMapJson(json: string) {
	let resultMap: HotKeysMap = defaultHotKeys;

	try {
		if (json) resultMap = JSON.parse(json);
	} catch (e) {
		//
	}

	return resultMap;
}

export function parseKeyCodeToName(key: string): string {
	switch (key) {
		case ' ':
			return 'Space';
		case MODIFIER_CTRL:
			return 'Ctrl (⌃)';
		case MODIFIER_SHIFT:
			return 'Shift (⇧)';
		case MODIFIER_ALT:
			return 'Alt (⌥)';
		case MODIFIER_META:
			return '⌘/⊞';
		case ARROW_LEFT:
			return '←';
		case ARROW_RIGHT:
			return '→';
		default:
			return key;
	}
}

export function makeKeyboardListener(keys: HotKey, event: KeyboardEvent, func: () => any) {
	if (!event.composed || !event.key) return;

	const eventCheck: boolean[] = keys.reduce(
		(memo, curr) => [
			...memo,
			curr.toLocaleUpperCase() === event.key.toLocaleUpperCase() ||
				(curr === MODIFIER_ALT && event.altKey) ||
				(curr === MODIFIER_SHIFT && event.shiftKey) ||
				(curr === MODIFIER_META && event.metaKey) ||
				(curr === MODIFIER_CTRL && event.ctrlKey),
		],
		[],
	);

	if (eventCheck.length > 0 && !has(eventCheck, false)) func();
}
