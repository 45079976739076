import Box from '@mui/material/Box';
import { UserAvatar } from '../../ui/Avatar';
import { ProjectUserInfo } from '../types/api';

export default function ShortUserInfo({ email, userName, firstName, lastName }: ProjectUserInfo) {
	return (
		<Box display="flex" alignItems="center">
			<UserAvatar email={email} size="small" />
			<Box ml="8px" display="flex" flexDirection="column" fontSize="12px">
				<span>
					{firstName} {lastName}
				</span>
				{userName && userName !== email && <span>{userName}</span>}
				{email && <span>{email}</span>}
			</Box>
		</Box>
	);
}
