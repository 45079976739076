import React from 'react';
import { useFormikContext } from 'formik';

type FormFieldWrapperProps = { component: React.FC<any>; name: string; compProps?: any };

export default function FormFieldWrapper({
	component: Comp,
	name,
	compProps,
}: FormFieldWrapperProps) {
	const { setFieldValue, values = {} } = useFormikContext<any>();

	const onChange = React.useCallback(
		(newVal) => setFieldValue(name, newVal),
		[name, setFieldValue],
	);

	return <Comp {...compProps} value={name ? values[name] : ''} onChange={onChange} name={name} />;
}
