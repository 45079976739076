import React from 'react';
import { css, cx } from '@emotion/css/macro';
import defaultUser from '../../../images/user.png';
import { useGravatar } from '../../core/hooks';

const rootClass = css`
	border-radius: 50%;
	overflow: hidden;
	box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
`;

const avatarClass = css`
	margin: auto;
	height: 100%;
	width: 100%;
	object-fit: cover;
`;

const sizeInPx: Record<UserAvatarSize, number> = {
	veryThick: 128,
	large: 64,
	medium: 40,
	small: 32,
};

export type UserAvatarSize = 'veryThick' | 'large' | 'medium' | 'small';

export type UserAvatarProps = {
	size?: UserAvatarSize;
	email?: string;
	className?: string;
};

// TODO Initials as default/fallback image?
export default function UserAvatar({ size = 'medium', email, className }: UserAvatarProps) {
	const [loaded, setLoaded] = React.useState<boolean>(false);

	const avatar = useGravatar(email);

	React.useEffect(() => {
		const img = new Image();
		img.src = avatar;
		img.onload = () => {
			setLoaded(true);
		};
		img.onerror = () => {
			setLoaded(false);
		};
	}, [avatar]);

	const finalSize = sizeInPx[size];

	const containerClass = css`
		width: ${finalSize}px;
		height: ${finalSize}px;
		min-width: ${finalSize}px;
		min-height: ${finalSize}px;
	`;

	return (
		<div className={cx(rootClass, containerClass, className)}>
			<img alt="Avatar" src={loaded ? avatar : defaultUser} className={avatarClass} />
		</div>
	);
}
