import React from 'react';
import { useQuery } from '@apollo/client';
import Typography from '@material-ui/core/Typography';
import { PureDraftField } from '../../../shared/modules/draft/FormDraftField';
import {
	GetUsersOfProjectQuery,
	GetUsersOfProjectQueryVariables,
} from '../../../graphql/typescript';
import { GET_USERS_OF_PROJECT_QUERY } from '../../../graphql/domains/project/queryList';
import { getProjectSubRoleNames } from '../../auth/helpers';
import { roleTitles } from '../../auth/constants';
import { getAvatarUrl } from '../../core/helpers';
import FormFieldWrapper from '../../../shared/modules/formaggio/components/FormFieldWrapper';

type WorkflowDraftFieldProps = {
	value?: string;
	onChange: any;
	placeholder?: string;
	editorKey: string;
	projectId: string;
	className?: string;
	name?: string;
};

export function WorkflowDraftField({
	value,
	onChange,
	placeholder,
	editorKey,
	projectId,
	className,
	name,
}: WorkflowDraftFieldProps) {
	const { data } = useQuery<GetUsersOfProjectQuery, GetUsersOfProjectQueryVariables>(
		GET_USERS_OF_PROJECT_QUERY,
		{
			variables: { projectId, includeContacts: true },
		},
	);
	const mappedSuggestions = React.useMemo(
		() =>
			(data?.list?.data || []).map(({ id, name, projectRole, subRole, email }) => ({
				id,
				name:
					name +
					' (' +
					(subRole ? getProjectSubRoleNames(subRole) + ' ' : '') +
					roleTitles[projectRole] +
					')',
				avatar: getAvatarUrl(email),
				email,
			})),
		[data?.list?.data],
	);

	return (
		<>
			<Typography variant="caption">
				Use @ symbol to mention project participants or contacts
			</Typography>
			<PureDraftField
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				editorKey={editorKey}
				mentions={mappedSuggestions}
				className={className}
				name={name}
			/>
		</>
	);
}

export function WorkflowFormDraftField({
	name,
	...rest
}: Omit<WorkflowDraftFieldProps, 'onChange'>) {
	return (
		<FormFieldWrapper component={WorkflowDraftField} name={name} compProps={{ name, ...rest }} />
	);
}
