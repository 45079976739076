import { Box } from '@mui/material';
import { useAuthContext } from '../../auth/context';
import { ItemStatus } from '../../core/types/api';
import { flagSumToArray } from '../../core/helpers';
import { progressStatuses } from '../../project/progressCalculation';

// const workflowRoleDetails: Record<WorkflowRole, { title: string; remark?: string }> = {
// 	[WorkflowRole.AssociateQsa]: { title: 'Associate QSA', remark: 'Trainee' },
// 	[WorkflowRole.Qsa]: { title: 'QSA', remark: 'Including mentor' },
// 	[WorkflowRole.PeerReviewer]: { title: 'Peer reviewer' },
// 	[WorkflowRole.Customer]: { title: 'Customer' },
// 	[WorkflowRole.QA]: { title: 'QA' },
// };

function renderLegend(statusMatrix: Record<string, Record<string, ItemStatus>>) {
	return Object.entries(statusMatrix).map(([fromStatus, roleToStatus]) => (
		<Box key={fromStatus} mb="8px">
			{fromStatus} status
			{Object.entries(roleToStatus).map(([role, toStatuses]) => (
				<Box key={role} ml="32px">
					{role === '0' ? 'anyone' : role} can move to
					{flagSumToArray(toStatuses).map((toStatus) => (
						<Box key={toStatus} ml="32px">
							{progressStatuses[toStatus].tileTitle}
						</Box>
					))}
				</Box>
			))}
		</Box>
	));
}

export default function WorkflowStatusHelp() {
	const { workflowStatusMatrix } = useAuthContext();

	if (!workflowStatusMatrix) return null;

	const legend = renderLegend(workflowStatusMatrix);

	return <Box>{legend}</Box>;
}
