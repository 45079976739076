import React from 'react';
import { ContactPage } from '@mui/icons-material';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Box, List, ListItemButton, ListItemText } from '@mui/material';
import Typography from '@material-ui/core/Typography';
import { Button, Loading } from '../core/components';
import { useToggle } from '../core/hooks';
import NewContactFlyOut from '../contact/components/NewContactFlyOut';
import { useAllowedMethods } from '../auth/hooks';
import { useProjectInfo } from '../project/hooks';
import { Requestee, RequesteeWithDesc } from './Request';
import { useContactAndUserList } from '../users/hooks';
import { hasFlag } from '../core/helpers';
import { Roles } from '../core/types/api';

type RequesteeDialogProps = {
	projectId: string;
	onChoose: (client: any) => void;
	alreadyRequestedIds?: string[];
	requestees: Requestee[];
	itemResponsibility?: number;
};

export default function RequesteeDialog({
	projectId,
	onChoose,
	alreadyRequestedIds,
	requestees,
	itemResponsibility,
}: RequesteeDialogProps) {
	const { 'Contact/CreateContact': canCreateContact } = useAllowedMethods();

	const { clientId } = useProjectInfo(projectId).data;

	const { isOn: isResponsibilityAreaOn, handleCheckbox } = useToggle(!!itemResponsibility);

	const { list, refetch: refetchContacts } = useContactAndUserList({
		projectId,
		includeContacts: true,
		isResponsibilityAreaOn,
		responsibilityArea: itemResponsibility,
	});

	const mappedList = React.useMemo(() => {
		if (!list) return <Loading />;

		return list
			.filter(({ projectRole }) =>
				hasFlag(Roles.Client + Roles.ExecutiveClientRepresentative + Roles.Contact, projectRole),
			)
			.filter(
				({ id = '' }) =>
					alreadyRequestedIds?.indexOf(id) < 0 && requestees?.map((r) => r.id).indexOf(id) < 0,
			)
			.map(({ id, ...req }) => {
				const choose = req.email ? () => onChoose({ id, ...req }) : undefined;

				return (
					<ListItemButton key={id} onClick={choose}>
						<ListItemText primary={<RequesteeWithDesc id={id} {...req} />} />
					</ListItemButton>
				);
			});
	}, [list, alreadyRequestedIds, requestees, onChoose]);

	const { isOn, toggleOn, toggleOff } = useToggle();

	const onCreatedContact = React.useCallback(
		(response) => {
			if (!refetchContacts) return;
			refetchContacts().then(() => {
				onChoose({ ...response?.data, isUser: false });
				toggleOff();
			});
		},
		[onChoose, toggleOff, refetchContacts],
	);

	return (
		<>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				<Typography>Select user / contact</Typography>
				{canCreateContact && (
					<Button onClick={toggleOn} color="secondary" startIcon={<ContactPage />}>
						New Contact
					</Button>
				)}
			</Box>
			{!!itemResponsibility && (
				<FormControlLabel
					control={
						<Checkbox checked={isResponsibilityAreaOn} onChange={handleCheckbox} color="primary" />
					}
					label="Responsible for this area"
				/>
			)}
			<List>{mappedList}</List>
			<NewContactFlyOut
				isOpen={isOn}
				onClose={toggleOff}
				clientId={clientId}
				onSuccess={onCreatedContact}
			/>
		</>
	);
}
