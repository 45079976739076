import React from 'react';
import { css } from '@emotion/css/macro';
import { Delete, Quiz } from '@mui/icons-material';
import { Alert, Box } from '@mui/material';
import { useAllowedMethods, useReportAccess } from '../../auth/hooks';
import { CheckboxField, FileField, FileForm } from '../../formaggio';
import { Button } from '../../core/components';
import { WorkflowSharedProps } from './WorkflowList';
import Request from '../../request/Request';
import { useToggle } from '../../core/hooks';
import { error } from '../../ui/Core/stylesheets/colors';
import { ItemStatus } from '../../project/enums';
import { RolesEnum } from '../../auth/enums';
import WorkflowStatusSelector from './WorkflowStatusSelector';
import { MAX_PROJECT_ATTACHMENT_SIZE_MB } from '../../evidence/constants';
import { WorkflowFormDraftField } from './WorkflowDraftField';

const rootClass = css`
	padding: 8px;
	position: sticky;
	bottom: 0;
	background: white;
`;

const topControlsClass = css`
	display: flex;
	align-items: center;
	margin: 8px 0;
`;

const controlClass = css`
	margin-right: 8px !important;
`;

const hintClass = css`
	margin-top: 8px;
`;

const messageClass = css`
	margin-bottom: 8px;
	max-height: 300px;
`;

type WorkflowCreateProps = WorkflowSharedProps & {
	alreadyRequestedIds?: string[];
	onSuccess?: () => void;
	isBulk?: boolean;
	bulkStatusFilter?: ItemStatus;
};

export default function WorkflowCreate({
	modelId,
	itemId,
	refresh,
	alreadyRequestedIds,
	onSuccess,
	isBulk,
	itemResponsibility,
}: WorkflowCreateProps) {
	const { 'Workflow/Create': canCreateWorkflow } = useAllowedMethods();
	const [request, setRequest] = React.useState<Request | undefined>();
	const clearRequest = React.useCallback(() => setRequest(undefined), [setRequest]);
	const { isOn, toggleOn, toggleOff } = useToggle();
	const { role, isAQSA, isClient } = useReportAccess();

	if (!canCreateWorkflow || !modelId) return null;

	return (
		<>
			<FileForm
				url="Workflow/Create"
				onUploadCompleted={() => {
					clearRequest();
					refresh();
					if (onSuccess) onSuccess();
				}}
				initialValues={{ visibleToCustomers: true, isBulk }}
				additional={{ projectId: modelId, itemId, request }}
				className={rootClass}
				maxUploadSizeMb={MAX_PROJECT_ATTACHMENT_SIZE_MB}
			>
				<WorkflowFormDraftField
					name="message"
					className={messageClass}
					placeholder="Type your message here..."
					editorKey={itemId}
					projectId={modelId}
				/>
				<FileField multiple name="attachments" />
				<Box display="flex" flexWrap="wrap" alignItems="center" maxWidth="800px">
					<div className={topControlsClass}>
						<Button color="primary" type="submit" className={controlClass}>
							Submit
						</Button>
						{isClient && (
							<>
								{!isBulk && (
									<Button
										startIcon={<Quiz />}
										color="secondary"
										size="small"
										onClick={toggleOn}
										className={controlClass}
									>
										Response Request
									</Button>
								)}
								{!!request && (
									<Button
										startIcon={<Delete htmlColor={error} />}
										size="small"
										onClick={clearRequest}
										className={controlClass}
									>
										Clear
									</Button>
								)}
							</>
						)}
					</div>
					{(isAQSA || role === RolesEnum.TechnicalEditor) && !request && (
						<>
							<WorkflowStatusSelector isBulk={isBulk} />
							<CheckboxField
								name="visibleToCustomers"
								label="Customer visible entry"
								className={controlClass}
							/>
							{isAQSA && (
								<CheckboxField
									name="isWorkPaper"
									label="Worknote"
									helperText="Entry for workpaper, not visible to customers"
									className={controlClass}
								/>
							)}
						</>
					)}
				</Box>
				{!!request && (
					<Alert severity="info" className={hintClass}>
						Request is attached. Click &quot;Submit&quot; to send. Optionally, enter a message that
						will be sent with the request.
					</Alert>
				)}
			</FileForm>
			{!isBulk && (
				<Request
					alreadyRequestedIds={alreadyRequestedIds}
					modelId={modelId}
					isOpen={isOn}
					onClose={toggleOff}
					setRequest={setRequest}
					itemResponsibility={itemResponsibility}
				/>
			)}
		</>
	);
}
