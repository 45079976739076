import { ItemStatus } from '../project/enums';
import ItemProgressStatus from './ItemProgressStatus';
import { useItemStatus } from './hooks';

export default function CellStatus() {
	const status = useItemStatus();

	if (status === undefined || status === ItemStatus.NotStarted) return null;

	return <ItemProgressStatus status={status} />;
}
