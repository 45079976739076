import React from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { css, cx } from '@emotion/css/macro';
import { darkgrey, mainLight } from '../../ui/Core/stylesheets/colors';
import { ARROW_LEFT, ARROW_RIGHT, MODIFIER_CTRL } from '../../hotkeys/constants';
import HotKeyListener from '../../hotkeys/HotKeyListener';
import HotKeyDisplay from '../../hotkeys/HotKeyDisplay';

type PageButtonProps = {
	onClick?: (number: React.ReactNode) => void;
	label: React.ReactNode;
	active?: boolean;
} & ButtonProps;

function PageButton({ onClick, label, active, ...rest }: PageButtonProps) {
	const clickHandler = React.useCallback(() => {
		if (onClick) onClick(typeof label === 'number' ? label : undefined);
	}, [onClick, label]);

	return (
		<Button
			variant="contained"
			{...rest}
			color={active ? 'primary' : 'inherit'}
			onClick={clickHandler}
			style={active ? { background: mainLight, color: 'white' } : {}}
		>
			{label}
		</Button>
	);
}

const prevKeys = [MODIFIER_CTRL, ARROW_LEFT];
const nextKeys = [MODIFIER_CTRL, ARROW_RIGHT];

const stickClass = css`
	position: sticky;
	top: 0;
	left: 0;
	background: white;
	border: 1px solid ${darkgrey};
	z-index: 1;
`;

type PaginationProps = {
	pagesCount: number;
	buttonsCount: number;
	selectPage: (number: number) => void;
	className?: string;
	page?: number;
	loading?: boolean;
};

export default function Pagination({
	pagesCount = 0,
	buttonsCount,
	selectPage,
	className,
	page,
	loading,
}: PaginationProps) {
	const pages = React.useMemo(
		() => [...Array(pagesCount > buttonsCount ? buttonsCount : pagesCount).keys()],
		[pagesCount, buttonsCount],
	);

	const renderPages = React.useMemo(
		() =>
			pages.map((number) => {
				const halfButtonsCount = Math.round(buttonsCount / 2) + 1;
				let calc = page + number - halfButtonsCount + halfButtonsCount / 2;
				if (page < halfButtonsCount) calc = number;

				if (pagesCount - page < halfButtonsCount)
					calc = number + pagesCount - halfButtonsCount - Math.round(buttonsCount / 4);

				const finalNumber = buttonsCount > pagesCount ? number : calc;

				const click = () => selectPage(finalNumber);

				const displayNumber = finalNumber + 1;

				if (displayNumber > pagesCount) return null;

				return (
					<PageButton
						key={finalNumber}
						onClick={click}
						active={finalNumber === page}
						label={displayNumber}
					/>
				);
			}),
		[page, pagesCount, buttonsCount, selectPage, pages],
	);

	const firstClick = React.useCallback(() => {
		selectPage(0);
	}, [selectPage]);

	const lastClick = React.useCallback(() => {
		selectPage(pagesCount - 1);
	}, [pagesCount, selectPage]);

	const step = React.useMemo(
		() => ({
			prev: () => selectPage(page - 1),
			next: () => selectPage(page + 1),
		}),
		[page, selectPage],
	);

	if (typeof page !== 'number' || pagesCount <= 1) {
		return null;
	}

	const isFirstPage = page <= 0;
	const isLastPage = page >= pagesCount - 1;

	return (
		<>
			{!loading && (
				<>
					{!isFirstPage && <HotKeyListener func={step.prev} kbdKey={prevKeys} />}
					{!isLastPage && <HotKeyListener func={step.next} kbdKey={nextKeys} />}
				</>
			)}
			<ButtonGroup variant="text" className={cx(stickClass, className)}>
				<PageButton
					onClick={step.prev}
					label={
						<>
							Prev <HotKeyDisplay hotKey={prevKeys} />
						</>
					}
					disabled={isFirstPage}
				/>
				<PageButton
					onClick={step.next}
					label={
						<>
							Next <HotKeyDisplay hotKey={nextKeys} />
						</>
					}
					disabled={isLastPage}
				/>
				<PageButton onClick={firstClick} label="First" disabled={isFirstPage} />
				<PageButton onClick={lastClick} label="Last" disabled={isLastPage} />
				{renderPages}
			</ButtonGroup>
		</>
	);
}
