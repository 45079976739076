import { HotKeyAction, HotKeysMap } from './types';

export const MODIFIER_ALT = 'Alt';
export const MODIFIER_CTRL = 'Ctrl';
export const MODIFIER_SHIFT = 'Shift';
export const MODIFIER_META = 'Meta';

export const ARROW_LEFT = 'ArrowLeft';
export const ARROW_RIGHT = 'ArrowRight';

export const hotKeysNaming: Record<HotKeyAction, string> = {
	reportNextPageOrStatusItem: 'Project navigation - Next page or item in status',
};

export const defaultHotKeys: HotKeysMap = {
	reportNextPageOrStatusItem: [MODIFIER_CTRL, ' '],
};
